import React from "react";
import Chart from "react-apexcharts";

const CumulativeFlowDiagram = ({}) => {
  const flowData = [
    {
      date: "2024-08-01",
      backlog: 20,
      todo: 15,
      inProgress: 10,
      qa: 5,
      done: 0,
    },
    {
      date: "2024-08-02",
      backlog: 18,
      todo: 17,
      inProgress: 12,
      qa: 7,
      done: 2,
    },
    {
      date: "2024-08-03",
      backlog: 15,
      todo: 18,
      inProgress: 15,
      qa: 10,
      done: 5,
    },
    {
      date: "2024-08-04",
      backlog: 14,
      todo: 16,
      inProgress: 14,
      qa: 12,
      done: 8,
    },
    {
      date: "2024-08-05",
      backlog: 12,
      todo: 15,
      inProgress: 12,
      qa: 13,
      done: 12,
    },
    {
      date: "2024-08-06",
      backlog: 10,
      todo: 12,
      inProgress: 10,
      qa: 14,
      done: 15,
    },
    {
      date: "2024-08-07",
      backlog: 8,
      todo: 10,
      inProgress: 8,
      qa: 15,
      done: 18,
    },
    {
      date: "2024-08-08",
      backlog: 6,
      todo: 8,
      inProgress: 6,
      qa: 15,
      done: 20,
    },
    {
      date: "2024-08-09",
      backlog: 5,
      todo: 6,
      inProgress: 5,
      qa: 15,
      done: 22,
    },
    {
      date: "2024-08-10",
      backlog: 3,
      todo: 4,
      inProgress: 3,
      qa: 16,
      done: 25,
    },
    {
      date: "2024-08-11",
      backlog: 1,
      todo: 2,
      inProgress: 1,
      qa: 17,
      done: 30,
    },
  ];

  const options = {
    chart: {
      type: "area",
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Cumulative Flow Diagram",
      align: "center",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Number of Tasks",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#F5FF33"],
  };

  const series = [
    {
      name: "Backlog",
      data: flowData.map((point) => [
        new Date(point.date).getTime(),
        point.backlog,
      ]),
    },
    {
      name: "To-Do",
      data: flowData.map((point) => [
        new Date(point.date).getTime(),
        point.todo,
      ]),
    },
    {
      name: "In Progress",
      data: flowData.map((point) => [
        new Date(point.date).getTime(),
        point.inProgress,
      ]),
    },
    {
      name: "QA",
      data: flowData.map((point) => [new Date(point.date).getTime(), point.qa]),
    },
    {
      name: "Done",
      data: flowData.map((point) => [
        new Date(point.date).getTime(),
        point.done,
      ]),
    },
  ];

  return <Chart options={options} series={series} type="area" height={350} />;
};

export default CumulativeFlowDiagram;
