import React from "react";
import Chart from "react-apexcharts";

const SprintVelocityChart = ({ data }) => {
  const options = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Sprint Velocity",
      align: "center",
    },
    xaxis: {
      categories: data.map((sprint) =>
        sprint.name?.length < 9 ? sprint.name : sprint.name.slice(0, 9) + "..."
      ),
      title: {
        text: "Sprints",
      },
    },
    yaxis: {
      title: {
        text: "Total Points Completed",
      },
    },
  };

  const series = [
    {
      name: "Completed Points",
      data: data.map((sprint) => sprint.done_points),
    },
    {
      name: "Total Points",
      data: data.map((sprint) => sprint.total_points),
    },
  ];

  return <Chart options={options} series={series} type="bar" height={350} />;
};

export default SprintVelocityChart;
