import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { Draggable } from "react-beautiful-dnd";
import { handleApiRequest } from "../../hooks/callApi";
import { useState } from "react";

export default function TaskCard({
  item,
  index,
  refetchSprintTasks,
  projectMembers,
}) {
  const [assigned_member_id, setAssigned_member_id] = useState(
    item?.assigned_member_id || null
  );
  const [open, setOpen] = useState(false);
  const handleSave = async () => {
    const response = await handleApiRequest(`/api/sprint-tasks`, "put", {
      assigned_member_id,
    });
    console.log("response", response);
    await refetchSprintTasks?.();
    setOpen(false);
  };
  return (
    <>
      <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Card sx={{ my: 1 }}>
              <CardHeader
                title={item.name}
                subheader={`Task Points: ${item.points}`}
                titleTypographyProps={{
                  variant: "body2",
                  color: "black",
                  fontWeight: 600,
                }}
                subheaderTypographyProps={{
                  variant: "caption",
                }}
                action={
                  <IconButton
                    size="small"
                    sx={{ color: "red" }}
                    onClick={async () => {
                      try {
                        const res = await handleApiRequest(
                          `/api/sprint-tasks/${item.id}`,
                          "delete"
                        );
                        console.log(res);
                        await refetchSprintTasks();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <DeleteOutline color="inherit" fontSize="inherit" />
                  </IconButton>
                }
              />
              <CardContent
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => setOpen(true)}
                >
                  <Avatar
                    sx={{
                      fontSize: "12px",
                      width: "24px",
                      height: "24px",
                      backgroundColor: "#F7B31E34",
                      color: "#333",
                      fontWeight: "bold",
                      border: "0.2px solid #F7B31E43",
                    }}
                  >
                    {/* {item.assignedTo.split(" ")[0][0]} */}
                  </Avatar>
                </IconButton>
                <Typography variant="body2" color="textSecondary" component="p">
                  {!item.end_date
                    ? "Not Provided"
                    : new Date(item.end_date).toLocaleDateString("en-us", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}
      </Draggable>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Choose Member For Task</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={projectMembers?.map((_) => ({
              id: _.id,
              name:
                _?.get_member_details?.first_name +
                " " +
                _?.get_member_details?.last_name,
            }))}
            getOptionLabel={(option) => option.name}
            value={
              assigned_member_id
                ? projectMembers
                    ?.map((_) => ({
                      id: _.id,
                      name:
                        _?.get_member_details?.first_name +
                        " " +
                        _?.get_member_details?.last_name,
                    }))
                    .find((u) => u.id === assigned_member_id)
                : null
            }
            onChange={(_e, newValue) => {
              setAssigned_member_id(newValue ? newValue.id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign a project member to this task"
                name="assignedUser"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
