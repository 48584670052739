import styled from "@emotion/styled";
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks";
import { handleApiRequest } from "../../hooks/callApi";
import SprintDetailsActions from "./SprintDetailsActions";
import TaskCard from "./TaskCard";

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 1vw;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

export default function SprintBoard() {
  const { sprintId, projectId } = useParams();

  const { data: projectMembersRes } = useFetch(
    `/api/project/members/${projectId}`
  );

  const { data: sprintTasks, refetch: refetchSprintTasks } = useFetch(
    `/api/sprint-tasks/${sprintId}`
  );
  const [columns, setColumns] = useState({
    backlog: {
      title: "Backlog",
      tasks: [],
      colors: ["#F075", "#FF6Fcc22"],
    },
    "to-dos": {
      title: "To Dos",
      tasks: [],
      colors: ["#29B6F633", "#00ACC133"],
    },
    "in-progress": {
      title: "In Progress",
      tasks: [],
      colors: ["#FF980333", "#FF572233"],
    },
    "q/a": {
      title: "Q/A",
      tasks: [],
      colors: ["#9C27B033", "#673AB733"],
    },
    done: {
      title: "Done",
      tasks: [],
      colors: ["#4CAF5033", "#388E3C33"],
    },
  });
  useEffect(() => {
    if (!sprintTasks)
      return setColumns({
        backlog: {
          title: "Backlog",
          tasks: [],
          colors: ["#F075", "#FF6Fcc22"],
        },
        "to-dos": {
          title: "To Dos",
          tasks: [],
          colors: ["#29B6F633", "#00ACC133"],
        },
        "in-progress": {
          title: "In Progress",
          tasks: [],
          colors: ["#FF980333", "#FF572233"],
        },
        "q/a": {
          title: "Q/A",
          tasks: [],
          colors: ["#9C27B033", "#673AB733"],
        },
        done: {
          title: "Done",
          tasks: [],
          colors: ["#4CAF5033", "#388E3C33"],
        },
      });
    setColumns((prev) => {
      const newColumns = { ...prev };
      sprintTasks.forEach((task) => {
        newColumns?.[task.status].tasks.push(task);
      });
      return newColumns;
    });
  }, [sprintTasks]);

  const onDragEnd = async (result, columns, setColumns) => {
    const idOfTask = result.draggableId;
    const newStatus = result.destination.droppableId;
    const res = await handleApiRequest(`/api/sprint-tasks/${idOfTask}`, "put", {
      status: newStatus,
    });
    if (res.status !== 200) return;
    await refetchSprintTasks();
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.tasks];
      const destItems = [...destColumn.tasks];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          tasks: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          tasks: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.tasks];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          tasks: copiedItems,
        },
      });
    }
  };
  return columns?.length ? (
    <>Loading</>
  ) : (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <TaskColumnStyles>
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <Droppable key={columnId} droppableId={`${columnId}`}>
              {(provided, snapshot) => (
                <TaskList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: `linear-gradient(135deg, ${column.colors})`,
                  }}
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Typography variant="subtitle1" fontWeight={"bold"}>
                        {column.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <SprintDetailsActions
                        status={column.title.toLowerCase().replace(" ", "-")}
                        refetchSprintTasks={refetchSprintTasks}
                      />
                    </Grid>
                  </Grid>
                  {column.tasks.map((item, index) => (
                    <TaskCard
                      key={item.id}
                      item={item}
                      index={index}
                      refetchSprintTasks={refetchSprintTasks}
                      projectMembers={
                        projectMembersRes?.data?.projectMembers?.data || []
                      }
                    />
                  ))}
                </TaskList>
              )}
            </Droppable>
          );
        })}
      </TaskColumnStyles>
    </DragDropContext>
  );
}
