// src/components/TaskCompletionHeatmap.js
import React from "react";
import Chart from "react-apexcharts";

const TaskCompletionHeatmap = ({}) => {
  const heatmapData = [
    {
      member: "Mathew",
      data: [
        { date: "2024-08-01", completedTasks: 5 },
        { date: "2024-08-02", completedTasks: 3 },
        { date: "2024-08-03", completedTasks: 8 },
        { date: "2024-08-04", completedTasks: 4 },
        { date: "2024-08-05", completedTasks: 7 },
      ],
    },
    {
      member: "Bikash",
      data: [
        { date: "2024-08-01", completedTasks: 3 },
        { date: "2024-08-02", completedTasks: 6 },
        { date: "2024-08-03", completedTasks: 2 },
        { date: "2024-08-04", completedTasks: 5 },
        { date: "2024-08-05", completedTasks: 9 },
      ],
    },
    {
      member: "Alex",
      data: [
        { date: "2024-08-01", completedTasks: 7 },
        { date: "2024-08-02", completedTasks: 8 },
        { date: "2024-08-03", completedTasks: 6 },
        { date: "2024-08-04", completedTasks: 7 },
        { date: "2024-08-05", completedTasks: 10 },
      ],
    },
  ];

  const options = {
    chart: {
      type: "heatmap",
    },
    title: {
      text: "Task Completion Heatmap",
      align: "center",
    },
    xaxis: {
      type: "category",
      title: {
        text: "Days/Weeks",
      },
    },
    yaxis: {
      title: {
        text: "Team Members",
      },
    },
    colorScale: {
      ranges: [
        {
          from: 0,
          to: 10,
          name: "Low",
          color: "#00A100",
        },
        {
          from: 11,
          to: 30,
          name: "Medium",
          color: "#FFB200",
        },
        {
          from: 31,
          to: 50,
          name: "High",
          color: "#FF0000",
        },
      ],
    },
    dataLabels: {
      enabled: true,
    },
  };

  const series = heatmapData.map((memberData) => ({
    name: memberData.member,
    data: memberData.data.map((entry) => ({
      x: entry.date,
      y: entry.completedTasks,
    })),
  }));

  return (
    <Chart options={options} series={series} type="heatmap" height={350} />
  );
};

export default TaskCompletionHeatmap;
