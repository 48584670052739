// src/components/BurnDownChart.js
import React from "react";
import Chart from "react-apexcharts";

const BurnDownChart = ({}) => {
  const sprintData = [
    { date: "2024-08-01", remainingPoints: 50 },
    { date: "2024-08-02", remainingPoints: 45 },
    { date: "2024-08-03", remainingPoints: 40 },
    { date: "2024-08-04", remainingPoints: 35 },
    { date: "2024-08-05", remainingPoints: 30 },
    { date: "2024-08-06", remainingPoints: 25 },
    { date: "2024-08-07", remainingPoints: 20 },
    { date: "2024-08-08", remainingPoints: 15 },
    { date: "2024-08-09", remainingPoints: 10 },
    { date: "2024-08-10", remainingPoints: 5 },
    { date: "2024-08-11", remainingPoints: 0 },
  ];

  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Remaining Points",
      },
    },
    markers: {
      size: 5,
    },
  };

  const series = [
    {
      name: "Remaining Points",
      data: sprintData.map((day) => ({
        x: new Date(day.date).getTime(),
        y: day.remainingPoints,
      })),
    },
  ];

  return <Chart options={options} series={series} type="line" height={350} />;
};

export default BurnDownChart;
