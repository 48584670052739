import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import CreateProjectForm from "../../components/dashboard/projects/CreateProjectForm";
import useSettings from "../../hooks/useSettings";
const WORKFLOW_TYPES = [
  {
    title: "Waterfall or Task Based Approach",
    image:
      "https://img.freepik.com/free-vector/agile-method-concept-illustration_114360-9983.jpg",
  },
  {
    title: "Agile Or Sprint Based Approach (Coming Soon)",
    image:
      "https://img.freepik.com/free-vector/group-office-workers-collaborating-tasks_1262-19784.jpg",
  },
];
export default function AddNewProject() {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(null);
  const { settings } = useSettings();
  const steps = [
    "Select your workflow",
    "Add other details",
    "View Created Project",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setSelectedWorkFlow(null);
  };

  const selectDiv = (divNumber) => {
    setSelectedWorkFlow(divNumber);
    handleNext();
  };
  const navigate = useNavigate();

  const StepContent = ({ step }) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={12}>
            {WORKFLOW_TYPES.map((item, index) => (
              <Grid item xs={6} key={index}>
                <CardActionArea>
                  <Card onClick={() => index === 0 && selectDiv(item.title)}>
                    <CardMedia
                      image={item.image}
                      style={{ height: 0, paddingTop: "56.25%" }}
                    />
                    <CardContent>
                      <Typography>{item.title}</Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        );
      case 1:
        return (
          <>
            <Typography sx={{ mb: 2 }}>
              Your selected workflow: <strong>{selectedWorkFlow}</strong>
            </Typography>
            <CreateProjectForm
              selectedWorkFlow={
                WORKFLOW_TYPES.map((item) => item.title).indexOf(
                  selectedWorkFlow
                ) + 1
              }
              handleNext={handleNext}
            />
          </>
        );
      case 2:
        return (
          <>
            <Box mt={2}>
              <Typography variant="h6">
                Project created successfully!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate("/dashboard/projects/view-all-projects")
                }
              >
                Go to Project List
              </Button>
            </Box>
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Add New Project</title>
      </Helmet>
      <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
        <Container maxWidth={settings.compact ? "xl" : false} sx={{ pt: 3 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <>
            {activeStep === steps.length ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ mt: 2, mb: 1, px: 10, py: 5 }}>
                  <StepContent step={activeStep} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === 1 && (
                    <Button onClick={handleNext}>Next</Button>
                  )}
                  {activeStep === 1 && (
                    <Button onClick={handleNext}>Finish</Button>
                  )}
                </Box>
              </>
            )}
          </>
        </Container>
      </Box>
    </>
  );
}
