import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import SprintBoard from "../../components/sprint/SprintBoard";
import SprintDetailsActions from "../../components/sprint/SprintDetailsActions";
import { useFetch } from "../../hooks";

export default function Sprint() {
  const { sprintId } = useParams();
  const { data: sprint } = useFetch(`/api/sprints/${sprintId}`);

  return (
    <Box p={2}>
      {/* Create an header with MUI components that contents Sprint title, start date and end date etc. */}
      <Grid container justifyContent="space-between" alignItems="center" px={2}>
        <Grid item>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {sprint?.name}
          </Typography>
          <Typography variant="caption" component={"h1"}>
            Goal: {sprint?.goal}
          </Typography>
          <Typography variant="caption">
            Duration: {sprint?.start_date} - {sprint?.end_date}
          </Typography>
        </Grid>
        <Grid item display={"flex"} gap={2}>
          {sprint && <SprintDetailsActions sprint={sprint} />}
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <SprintBoard />
    </Box>
  );
}
