import React from "react";
import Chart from "react-apexcharts";

const TaskStatusPieChart = ({}) => {
  const taskData = {
    backlog: 5,
    todo: 10,
    inProgress: 7,
    qa: 3,
    done: 20,
  };
  const options = {
    labels: ["Backlog", "To-Do", "In Progress", "QA", "Done"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    title: {
      text: "Task Status Distribution",
      align: "center",
    },
  };

  const series = [
    taskData.backlog,
    taskData.todo,
    taskData.inProgress,
    taskData.qa,
    taskData.done,
  ];

  return <Chart options={options} series={series} type="pie" height={250} />;
};

export default TaskStatusPieChart;
