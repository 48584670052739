import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useEffect, useState } from "react";
import EditSprint from "./EditSprint";
import { columnsFromBackend } from "./SprintData";
import useProject from "../../hooks/useProject";
import { useParams } from "react-router-dom";
import { handleApiRequest } from "../../hooks/callApi";

const TASK_STATUS = Object.values(columnsFromBackend).map((_) => ({
  id: _.title.toLowerCase().replace(" ", "-"),
  name: _.title,
}));

const taskPoints = [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 5, name: "5" },
  { id: 8, name: "8" },
  { id: 13, name: "13" },
];

export default function SprintDetailsActions({
  status,
  sprint,
  refetchSprintTasks,
}) {
  const { allProjectMembers, getAllProjectMembers } = useProject();
  const { projectId, sprintId } = useParams();
  const [open, setOpen] = useState(false);
  const [taskData, setTaskData] = useState({
    name: "",
    status: status,
    endDate: null,
    assignedUser: null,
    points: null,
  });

  useEffect(() => {
    if (allProjectMembers.length === 0) {
      getAllProjectMembers(projectId);
    }
  }, [projectId]);

  const handleInputChange = (e) => {
    setTaskData({
      ...taskData,
      [e.target.name]: e.target.value,
    });
  };

  const handleProjectChange = (event, newValue) => {
    setTaskData({
      ...taskData,
      status: newValue ? newValue.id : null,
    });
  };

  const handleUserChange = (event, newValue) => {
    setTaskData({
      ...taskData,
      assignedUser: newValue ? newValue.id : null,
    });
  };

  const handlePointsChange = (event, newValue) => {
    setTaskData({
      ...taskData,
      points: newValue ? newValue.id : null,
    });
  };

  const handleSave = async () => {
    const response = await handleApiRequest(`/api/sprint-tasks`, "post", {
      name: taskData.name,
      status: taskData.status,
      sprint_id: sprintId,
      assigned_member_id: taskData.assignedUser,
      end_date: taskData.endDate,
      points: taskData.points,
    });
    console.log("response", response);
    await refetchSprintTasks?.();
    setOpen(false);
  };

  return (
    <>
      {!status && <EditSprint sprint={sprint} />}
      <Tooltip title="Create Task">
        <IconButton
          // sx={{ color: "green" }}
          color="inherit"
          size="small"
          onClick={() => setOpen(true)}
        >
          <Add fontSize="small" />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Task</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Task Name"
                name="name"
                value={taskData.name}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={TASK_STATUS}
                getOptionLabel={(option) => option.name}
                value={
                  taskData.status
                    ? TASK_STATUS.find((p) => p.id === taskData.status)
                    : null
                }
                onChange={handleProjectChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Task Status"
                    name="status"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="End Date"
                name="endDate"
                type="date"
                value={taskData.endDate}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={allProjectMembers?.data?.map((_) => ({
                  id: _.id,
                  name:
                    _?.get_member_details?.first_name +
                    " " +
                    _?.get_member_details?.last_name,
                }))}
                getOptionLabel={(option) => option.name}
                value={
                  taskData.assignedUser
                    ? allProjectMembers?.data
                        ?.map((_) => ({
                          id: _.id,
                          name:
                            _?.get_member_details?.first_name +
                            " " +
                            _?.get_member_details?.last_name,
                        }))
                        .find((u) => u.id === taskData.assignedUser)
                    : null
                }
                onChange={handleUserChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assign a project member to this task"
                    name="assignedUser"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={taskPoints}
                getOptionLabel={(option) => option.name}
                value={
                  taskData.points
                    ? taskPoints.find((p) => p.id === taskData.points)
                    : null
                }
                onChange={handlePointsChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Points"
                    name="points"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
