import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ChevronRight,
  DeleteOutlineOutlined,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import AddSprint from "../../components/sprint/AddSprint";
import { useFetch } from "../../hooks";
import useSettings from "../../hooks/useSettings";
import { handleApiRequest } from "../../hooks/callApi";

export default function Sprints({ onlySprint }) {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const { projectId } = useParams();
  const { data: sprintsData, refetch: refetchSprints } = useFetch(
    `/api/sprints/project/${projectId}`
  );

  return (
    <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
      <Container maxWidth={settings.compact ? "xl" : false}>
        {!onlySprint && (
          <Helmet>
            <title>Dashboard: View All Sprints |</title>
          </Helmet>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            {!onlySprint && (
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Sprints
                </Typography>
              </Breadcrumbs>
            )}
            <Typography color="textPrimary" variant="dashboardHeading">
              Sprints
            </Typography>
          </Grid>
          {!!onlySprint && (
            <Grid item>
              <Box sx={{ m: -1 }}>
                <AddSprint refetchSprints={refetchSprints} />
              </Box>
            </Grid>
          )}
        </Grid>
        <List>
          {sprintsData?.map((sprint) => (
            <ListItem key={sprint.id} divider button>
              <ListItemText
                primary={sprint.name}
                secondary={`${sprint.start_date} - ${sprint.end_date}`}
                primaryTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "bold",
                }}
                secondaryTypographyProps={{ variant: "caption" }}
              />

              <Tooltip title="View Sprint">
                <IconButton
                  size="small"
                  onClick={() =>
                    navigate(
                      `/dashboard/projects/view-and-edit-project/${sprint.project_id}/sprints/${sprint.id}`
                    )
                  }
                >
                  <RemoveRedEyeOutlined fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete Sprint">
                <IconButton
                  size="small"
                  onClick={async () => {
                    const response = await handleApiRequest(
                      `/api/sprints/${sprint.id}`,
                      "delete"
                    );
                    console.log(response);
                    await refetchSprints();
                  }}
                >
                  <DeleteOutlineOutlined fontSize="inherit" color="error" />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Container>
    </Box>
  );
}
