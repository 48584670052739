import Chart from "react-apexcharts";
export default function GanttChart({ data }) {
  const options = {
    chart: {
      type: "rangeBar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const series = data.map((sprint) => ({
    name: sprint.name,
    data: [
      {
        x: sprint.name,
        y: [
          new Date(sprint.start_date).getTime(),
          new Date(sprint.end_date).getTime(),
        ],
      },
    ],
  }));

  return (
    <Chart options={options} series={series} type="rangeBar" height={350} />
  );
}
