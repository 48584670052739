import { Card, CardHeader, CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks";
import GanttChart from "./Charts/GanttChart";
import BurnDownChart from "./Charts/BurnDownChart";
import TaskStatusPieChart from "./Charts/TaskStatusPieChart";
import MemberContributionBarChart from "./Charts/MemberContributionBarChart";
import SprintVelocityChart from "./Charts/SprintVelocityChart";
import CumulativeFlowDiagram from "./Charts/CumulativeFlowDiagram";
import TaskCompletionHeatmap from "./Charts/TaskCompletionHeatmap";
import SprintProgressRadialBar from "./Charts/SprintProgressRadialBar";
export default function Dashboard() {
  const { projectId } = useParams();
  const { data: sprintsData } = useFetch(`/api/sprints/project/${projectId}`);
  const { data: sprintsVelocityData } = useFetch(
    `/api/sprints/velocity/${projectId}`
  );

  return (
    <Grid container spacing={2}>
      {/* Sprint Gantt Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Gantt Chart" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <GanttChart data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Task Status Pie Chart */}
      <Grid item md={6}>
        <Card sx={{ height: 320 }}>
          <CardHeader title="Sprint Task Status Pie Chart" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <TaskStatusPieChart data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Progress Radial Bar */}
      <Grid item md={6}>
        <Card sx={{ height: 320 }}>
          <CardHeader title="Sprint Progress Radial Bar" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <SprintProgressRadialBar data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Member Contribution Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Member Contribution" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <MemberContributionBarChart data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Velocity Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Velocity" />
          {!sprintsVelocityData ? (
            <CircularProgress />
          ) : (
            <SprintVelocityChart data={sprintsVelocityData} />
          )}
        </Card>
      </Grid>
      {/* Cumulative Flow Diagram Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Cumulative Flow Diagram" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <CumulativeFlowDiagram data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Task Completion Heatmap Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Task Completion Heatmap" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <TaskCompletionHeatmap data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Burn-Down Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Burn-Down Chart" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <BurnDownChart data={sprintsData} />
          )}
        </Card>
      </Grid>
      {/*  */}
    </Grid>
  );
}
